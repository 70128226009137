import React from 'react'
import Layout from '../components/Layout'

export default function About() {
    return (
        <Layout>
            <div>
                <h1>About Page</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus corporis fugiat non distinctio aspernatur maxime deserunt blanditiis iure eos tempore exercitationem, inventore itaque aliquam quibusdam sapiente voluptas ipsum minima eligendi!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus corporis fugiat non distinctio aspernatur maxime deserunt blanditiis iure eos tempore exercitationem, inventore itaque aliquam quibusdam sapiente voluptas ipsum minima eligendi!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus corporis fugiat non distinctio aspernatur maxime deserunt blanditiis iure eos tempore exercitationem, inventore itaque aliquam quibusdam sapiente voluptas ipsum minima eligendi!</p>
            </div>
        </Layout>
    )
}
